<template>
  <v-autocomplete
    v-model="facility"
    v-bind="$attrs"
    :items="$store.state.selects.facilities"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('facility', 2) | capitalize"
    small-chips
    cache-items
    dense
    multiple
    clearable
    menu-props="offsetY"
    item-text="name"
    item-value="uuid"
    @change="$emit('update:value', facility)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-hospital
      </v-icon>
    </template>
    <template #selection="{ item }">
      <v-chip
        x-small
        class="ml-0"
        v-text="item.name"
      />
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, Object, String],
        default: () => [],
      },
      params: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        facility: {},
        facilities: [],
      }
    },
    watch: {
      search () {
        this.fetchFacilities()
      },
      facilities () {
        this.facility = this.value.length ? this.value : []
      },
      value () {
        this.facility = this.value.length ? this.value : []
      },
    },
    created () {
      if (this.value.length && typeof this.value === 'string') {
        this.search = this.value
      } else if (this.$store.state.selects.facilities.length === 0) {
        this.fetchFacilities()
      }
    },
    methods: {
      fetchFacilities () {
        this.loading = true
        const promise = this.axios.get('facilities', {
          params: {
            ...this.params,
            ...{
              fields: ['uuid','name'],
              search: this.search,
              sort: 'name',
            },
          },
        })
        return promise.then((response) => {
          this.$store.state.selects.facilities = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
